import emptyRow from './emptyRow'
import genericVariantsRow from './genericVariantsRow'
import genericSubVariantsRow from './genericSubVariantsRow'
import subvariantsParentRow from './subvariantsParentRow'
import subvariantsChildRow from './subvariantsChildRow'
import swrParentRow from './swrParentRow'
import swrChildRow from './swrChildRow'
import moreDetailsRow from './moreDetailsRow'
import VARIANTS_TYPES from '../../config/variants-config'

export default class rowsFactory {
    constructor(type, props = {}) {
        if (type === VARIANTS_TYPES.EMPTY) return new emptyRow(props)
        if (type === VARIANTS_TYPES.GENERIC_SUBVARIANTS) return new genericSubVariantsRow(props)
        if (type === VARIANTS_TYPES.GENERIC_VARIANTS) return new genericVariantsRow(props)
        if (type === VARIANTS_TYPES.SUBVARIANTS_PARENT) return new subvariantsParentRow(props)
        if (type === VARIANTS_TYPES.SUBVARIANTS_CHILD) return new subvariantsChildRow(props)
        if (type === VARIANTS_TYPES.SWR_PARENT) return new swrParentRow(props)
        if (type === VARIANTS_TYPES.SWR_CHILD) return new swrChildRow(props)
        if (type === VARIANTS_TYPES.MORE_DETAILS) return new moreDetailsRow(props)
    }
}
