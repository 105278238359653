import rowsFactory from '../rowsFactory/rowsFactory'
import { selectRow } from '../partials/rowController'
import VARIANTS_TYPES from '../../config/variants-config'

export default class genericBodyWithVariants {
    constructor(props) {
        this.props = props
        this.tbody = document.createElement('tbody')
        this.type = props.type
        this.columnsData = props.type === VARIANTS_TYPES.GENERIC_VARIANTS ? props.data.columns : props.data.subColumns
        this.cart = props.cart
        this.productVariantPreselection = props.data.options.productVariantPreselection
    }
    render() {
        let isEven = false
        this.tbody.setAttribute('class', 'v-tbody generic-body-with-variants')
        this.props.data.variants.map((variant) => {
            const row = new rowsFactory(VARIANTS_TYPES.GENERIC_VARIANTS, { ...this.props, isEven, variantsData: variant, cart: this.cart }).render()
            const moreRow = new rowsFactory(VARIANTS_TYPES.MORE_DETAILS, { ...this.props, variantsData: variant, columnsData: this.columnsData }).render()
            this.tbody.appendChild(row)
            this.tbody.appendChild(moreRow)
            isEven = !isEven
        })
        if (this.productVariantPreselection) selectRow.first(this.type, this.tbody)
        return this.tbody
    }
}
