const initVariantsTableSearch = () => {
    const searchInput = $('.variants-table-search')

    searchInput.on('keyup blur change search', (e) => {
        const searchValue = e.target.value
        const genericVariantRow = $('.generic-variant-row > td[data-key="PartCode"]')
        const activeItem = $('.v-tr.generic-variant-row.active')

        activeItem.trigger('click')

        genericVariantRow.each(function () {
            let elem = $(this)
            let elemText = elem.text().toUpperCase()
            if (elemText.includes(searchValue.toUpperCase())) {
                elem.parent().show(200)
            } else {
                elem.parent().hide(200)
            }
        })
    })
}

const initVariantsTableToggle = () => {
    const toggleButton = $('.variants-table-toggle')
    const iconArrow = toggleButton.find('.icon-arrow')
    const targetElement = $('.filter')

    toggleButton.on('click', () => {
        targetElement.slideToggle('fast')
        iconArrow.toggleClass('up', 'down')
    })
}

export { initVariantsTableSearch, initVariantsTableToggle }
