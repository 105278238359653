//
// Dropdown download
// A dropdown acting as a select with download option

;(($) => {
    $(() => {
        let module = $('.dropdown-download')

        // Only continue if module is on page
        if (!module.length) return

        let downloadButton = module.find('.download')
        let dropdownItem = module.find('.dropdown-item')
        downloadButton.hide()

        dropdownItem.click(function () {
            let $this = $(this)
            let selectText = module.find('button > span')
            let itemText = $this.text()

            selectText.text(itemText)
            downloadButton.show()
            downloadButton.attr('href', $this.data('href'))
        })
    })
})(jQuery)
